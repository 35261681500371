import { Inbox } from "@novu/react";

import { useUser } from "../user";

// eslint-disable-next-line lingui/no-unlocalized-strings
const applicationIdentifier = import.meta.env.VITE_NOVU_APPLICATION_ID;

export default function InApp() {
  const { user } = useUser();
  const userId: string = user?.studentId ?? ""; // Fallback to empty string

  return <Inbox applicationIdentifier={applicationIdentifier} subscriberId={userId} />;
}
