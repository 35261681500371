import { t } from "@lingui/macro"; // Import localization method
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Button, Container, Stack, Tooltip, Typography } from "@mui/material";
import { AlertColor } from "@mui/material/Alert";
import { GridColDef, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { format } from "date-fns";
import { useEffect, useState } from "react";

import Alerts from "@/client/components/alerts";
import ChipLabel from "@/client/components/custom/custom-chip";
import MuiGridTable from "@/client/components/custom/grid-table";
import { useAppContext } from "@/client/hooks/context";
import { useUser } from "@/client/services/user";

const AssessMents = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const { user } = useUser();
  const { loading, setLoading, reloading } = useAppContext();
  const [eventId, setEventId] = useState<string>("");
  const userId = user?.email;

  useEffect(() => {
    const fetchData = () => {
      // no async here
      setLoading(true);
      try {
        const timer = setTimeout(async () => {
          // async inside setTimeout
          try {
            const res = await axios.get(`/api/integration/external-service/assessment`, {
              params: { email: userId },
            });
            setData(res.data.result);
            console.log(res.data.result);
          } catch (error) {
            console.error(error);
          } finally {
            setLoading(false);
          }
        }, 1000);
        return () => {
          clearTimeout(timer);
        };
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [reloading]);

  const handleEventStatus = (status: string) => {
    switch (status) {
      case "invited": {
        return (
          <ChipLabel
            color="warning"
            label={t`Invited`}
            sx={{ fontSize: "12px", p: "0px", height: "28px" }}
          />
        );
      }
      case "accepted": {
        return (
          <ChipLabel
            color="success"
            label={t`Accepted`}
            sx={{ fontSize: "12px", p: "0px", height: "28px" }}
          />
        );
      }
      case "declined": {
        return (
          <ChipLabel
            color="error"
            label={t`Declined`}
            sx={{ fontSize: "12px", p: "0px", height: "28px" }}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  const handleStatusOpen = (id: string) => {
    setOpen(true);
    setEventId(id);
  };

  // Format: Oct 31 07:00 PM
  const formatDate = (dateString: string | null) => {
    if (!dateString) return "--";
    const date = new Date(dateString);
    // eslint-disable-next-line lingui/no-unlocalized-strings
    const dateFormat = "MMM dd hh:mm a";
    return format(date, dateFormat);
  };

  const rows = data.map((item: any) => ({
    id: item.id,
    name: item.name,
    code: item.code,
    category: item.category === "practice" ? "Practice " : "Final ",
    questionCount: item.questionCount,
    score: item.score === null ? "--" : item.score,
    totalScore: item.totalScore,
    startedAt: item.startedAt,
    completedAt: item.completedAt,
    status: item.status,
  }));
  const handleStudentStatus = (status: string) => {
    switch (status) {
      case "draft": {
        return (
          <ChipLabel
            // color="green"
            label="Draft"
            sx={{
              color: "blue.main",
              backgroundColor: "blue.light",
              fontSize: "12px",
              p: "0px",
              height: "28px",
            }}
          />
        );
      }

      case "completed": {
        return (
          <ChipLabel
            // color="green"
            label="Completed"
            sx={{
              color: "green.main",
              backgroundColor: "green.light",
              fontSize: "12px",
              p: "0px",
              height: "28px",
            }}
          />
        );
      }

      case "pending": {
        return (
          <ChipLabel
            color="error"
            label="Pending"
            sx={{
              color: "red.main",
              backgroundColor: "red.light",
              fontSize: "12px",
              p: "0px",
              height: "28px",
            }}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  const columns: GridColDef[] = [
    {
      field: "code",
      headerName: t`Code`,
      width: 80,
      headerAlign: "center",
      align: "center",
      hideable: false,
    },
    {
      field: "name",
      headerName: t`Test Name`,
      width: 150,
      headerAlign: "left",
      align: "left",
      hideable: false,
    },
    {
      field: "category",
      headerName: t`Category`,
      width: 100,
      headerAlign: "left",
      align: "left",
      hideable: false,
    },

    {
      field: "questionCount",
      headerName: t` Questions`,
      headerAlign: "center",
      align: "center",
      width: 100,
      editable: false,
    },
    {
      field: "totalScore",
      headerName: t` Score`,
      headerAlign: "center",
      align: "center",
      width: 70,
      editable: false,
    },

    {
      field: "startedAt",
      headerName: t`Start Time`,
      width: 140,
      headerAlign: "center",
      align: "center",
      hideable: false,
      renderCell: (params) => formatDate(params.value),
    },
    {
      field: "completedAt",
      headerName: t`End Time`,
      width: 140,
      headerAlign: "center",
      align: "center",
      hideable: false,
      renderCell: (params) => formatDate(params.value),
    },
    {
      field: "status",
      headerName: t`Status`,
      headerAlign: "center",
      align: "center",
      width: 120,
      editable: false,
      renderCell: (params) => handleStudentStatus(params.row.status),
    },

    {
      field: "action",
      headerName: t`Action`,
      width: 80,
      headerAlign: "center",
      align: "right",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Stack direction="row">
          <Button
            variant="contained"
            color="primary"
            sx={{ textTransform: "none", fontSize: "10px", height: "25px", mt: "3px" }}
            disabled={params.row.status === "completed"}
            onClick={() => {
              handleStatusOpen(params.row.id);
            }}
          >
            <Tooltip title={t`Attend Interview`}>
              <a
                href={`${import.meta.env.VITE_ASSESSMENTS_WEB_APP_URL}/?code=${params.row.code}&email=${userId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                Attend <ArrowForwardIcon sx={{ color: "white", fontSize: "13px" }} />
              </a>
            </Tooltip>
          </Button>
        </Stack>
      ),
    },
  ];

  const [alert, setAlert] = useState<{ open: boolean; message: string; severity: AlertColor }>({
    open: false,
    message: "",
    severity: "info",
  });

  const handleCloseAlert = () => {
    setAlert({
      open: false,
      message: "",
      severity: "info",
    });
  };

  return (
    <Box>
      <Alerts
        open={alert.open}
        message={alert.message}
        severity={alert.severity}
        onClose={handleCloseAlert}
      />

      <Container>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="h6"
            component="div"
            sx={{ fontWeight: "bold", fontSize: "1.2rem", pb: 1 }}
          >
            {t`Assessments`}
          </Typography>
        </Box>

        <Box sx={{ height: "75vh", mt: 5 }}>
          <MuiGridTable
            disableColumnSelector
            rows={rows}
            columns={columns}
            loading={loading}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
              },
            }}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default AssessMents;
